* {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	background: transparent;
	font-weight: normal;
	font-style: normal;
	text-decoration: none;
	outline: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
body {
	display: block;
}

@font-face {
	font-display: auto;
	font-family: "product_sansregular";
	src: url("../fonts/productsans/productsans-regular-webfont.woff2")
			format("woff2"),
		url("../fonts/productsans/productsans-regular-webfont.woff")
			format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-display: auto;
	font-family: "product_sansbold";
	src: url("../fonts/productsans/productsans-bold-webfont.woff2")
			format("woff2"),
		url("../fonts/productsans/productsans-bold-webfont.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-display: auto;
	font-family: gordita_regular;
	src: url(../fonts/gordita/GorditaRegular.woff) format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-display: auto;
	font-family: gordita_medium;
	src: url(../fonts/gordita//GorditaMedium.woff) format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: gordita_bold;
	src: url(../fonts/gordita/GorditaBold.woff) format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Gabriola";
	font-style: normal;
	font-weight: normal;
	src: local("Gabriola"),
		url("../fonts/gabrolia/gabriola\ Font\ Download.woff") format("woff");
}
@font-face {
	font-family: "Lato";
	src: url("../fonts/lato/Lato-Regular.woff2") format("woff2"),
		url("../fonts/lato/Lato-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Lato";
	src: url("../fonts/lato/Lato-Medium.woff2") format("woff2"),
		url("../fonts/lato/Lato-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Lato";
	src: url("../fonts/lato/Lato-Light.woff2") format("woff2"),
		url("../fonts/lato/Lato-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
